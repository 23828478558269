<template>
  <div class="home">
    <el-carousel class="carousel" height="42vw" :autoplay="true" :interval="5000" trigger="click" >
      <el-carousel-item>
        <a href="/HOTAN物联网断路器产品手册-202403.pdf" target="_blank">
          <img style="width: inherit;min-height: 524px;" src="../assets/home/carousel1.jpg" alt="" srcset="">
        </a>
      </el-carousel-item>
      <el-carousel-item>
        <a href="https://www.eztcloud.com" target="_blank">
          <img style="width: inherit;min-height: 524px;" src="../assets/home/carousel2.jpg" alt="" srcset="">
        </a>
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img style="width: inherit;min-height: 524px;" src="../assets/home/carousel3.png" alt="" srcset="">
      </el-carousel-item> -->
    </el-carousel>
    <div class="part1 flex justify-around items-start" style="margin:200px;"> 
      <div class="item flex-1 flex flex-col justify-center items-center">
        <div class="icbox flex justify-center items-center">
          <div class="bg-white flex justify-center items-center rounded-full" style="width:220px;height:220px;">
            <img src="../assets/home/自主学习 1@2x.png">
          </div>
        </div>
        <div class=" text-3xl text-black py-5">{{ $t('message.home.slogan.title1') }}</div>
        <div class=" text-2xl leading-9 text-gray-500">{{ $t('message.home.slogan.content1') }}</div>
        <div class=" text-2xl leading-9 text-gray-500">{{ $t('message.home.slogan.content1b')  }}</div>
      </div>
      <div class="item flex-1 flex flex-col justify-center items-center">
        <div class="icbox flex justify-center items-center">
          <div class="bg-white flex justify-center items-center rounded-full" style="width:220px;height:220px;">
            <img src="../assets/home/思维导图 1@2x.png">
          </div>
        </div>
        <div class=" text-3xl text-black py-5">{{ $t('message.home.slogan.title2') }}</div>
        <div class=" text-2xl leading-9 text-gray-500">{{ $t('message.home.slogan.content2') }}</div>
      </div>
      <div class="item flex-1 flex flex-col justify-center items-center">
        <div class="icbox flex justify-center items-center">
          <div class="bg-white flex justify-center items-center rounded-full" style="width:220px;height:220px;">
            <img src="../assets/home/黑科技 1@2x.png">
          </div>
        </div>
        <div class=" text-3xl text-black py-5">{{ $t('message.home.slogan.title3') }}</div>
        <div class=" text-2xl leading-9 text-gray-500">{{ $t('message.home.slogan.content3') }}</div>
      </div>
    </div>
    <div class="part2 flex" style="margin:0 auto;width:80%;margin-bottom:150px;">
      <div style="width: 270px;">
        <div style="height:120px;" class="cpzx flex justify-center items-center text-2xl font-bold text-white">{{ $t('message.home.product.title') }}</div>
        <div class="bg-red-700 text-white inline-block" style="height:640px;width:100%;">
          <div class="mt-9 text-xl">{{ $t('message.home.product.p1')  }}</div>
          <div class="mt-9 text-xl">{{ $t('message.home.product.p2') }}</div>
          <!-- <div class="mt-9 text-xl">{{ $t('message.home.product.p3') }}</div> -->
          <div class="mt-9 text-xl">{{ $t('message.home.product.p4') }}</div>
          <div class="mt-9 text-xl">{{ $t('message.home.product.p5') }}</div>
          <div class="mt-9 text-xl">{{ $t('message.home.product.p6') }}</div>
          <div class="mt-9 text-xl">{{ $t('message.home.product.p7') }}</div>
          <div class="mt-9 text-xl">{{ $t('message.home.product.p8') }}</div>
        </div>
      </div>
      <div class="flex-1 flex flex-col" style="background: #EDEDED;text-align: center;">
        <div class=" flex-1 flex justify-around items-center">
          <div class="part">
            <div class="imgbox">
              <img src="../assets/home/Group 47.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p1') }}</div>
          </div>
          <div class="part">
            <div class="imgbox">
              <img src="../assets/home/pc2.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p2') }}</div>
          </div>
          <!-- <div class="part">
            <div class="imgbox">
              <img src="../assets/home/pc3.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p3') }}</div>
          </div> -->
          <div class="part">
            <div class="imgbox">
              <img src="../assets/home/pc4.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p4') }}</div>
          </div>
        </div>
        <div class="bg-red-700 h-12"></div>
        <div class="flex-1 flex justify-around items-center">
          <div class="part">
            <div class="imgbox">
              <img src="../assets/home/xbz.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p5') }}</div>
          </div>
          <div class="part">
            <div class="imgbox">
              <img src="../assets/home/mp1.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p6') }}</div>
          </div>
          <div class="part">
            <div class="imgbox">
              <img src="../assets/home/66f.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p7') }}</div>
          </div>
          <div class="part">
            <div class="imgbox">
              <img src="../assets/home/pc8.png" alt="" srcset="">
            </div>
            <div class="namebox text-lg text-gray-900 mt-9">{{ $t('message.home.product.p8') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="divide" style="background: rgba(0,0,0,0.8);margin-bottom:150px;">
      <div class="flex items-center">
        <span class="arrow-lef mr-16"><img src="../assets/home/arr-l.png" alt="" srcset=""></span>
        <span class="text-white" style="font-size: 48px;z-index: 1;">{{ $t('message.home.features.title') }}</span>
        <span class="arrow-right ml-16"><img src="../assets/home/arr-r.png" alt="" srcset=""></span>
      </div>
      <div class=" tracking-wider" style="color: rgba(241,241,241,0.3);font-size: 48px;margin-top:-45px;">PRODUCT CHARACTERISTICS</div>
    </div>
    <div class="part4 relative" style="height:1800px;">
      <div style="width:85%;height:750px;" class="absolute left-0 top-20 bg-red-700" >
        <div class="absolute" style="top:-100px;left:100px;width:46%;"><img src="../assets/home/Group 55.png" alt=""></div>
        <div class="absolute  text-left text-white" style="right:60px;top:110px;width:600px;">
          <h6 style="font-size: 24px;height: 30px;">{{ $t('message.home.features.product1.name') }}</h6>
          <ul style="list-style: disc;font-size: 18px;line-height: 28px;">
            <li>{{ $t('message.home.features.product1.content1') }}</li>
            <li>{{ $t('message.home.features.product1.content2') }}</li>
            <li>{{ $t('message.home.features.product1.content3') }}</li>
          </ul>
        </div>
        <div class="absolute" style="bottom:-100px;right:-180px;width:46%;"><img src="../assets/home/p2.png" alt=""></div>
        <div  class="absolute text-left text-white" style="left:20%;bottom:150px;width:600px;">
          <h6 style="font-size: 24px;height: 30px;">{{ $t('message.home.features.product2.name') }}</h6>
          <ul style="list-style: disc;font-size: 18px;line-height: 28px;">
            <li>{{ $t('message.home.features.product2.content1') }}</li>
            <li>{{ $t('message.home.features.product2.content2') }}</li>
          </ul>
        </div>
      </div>
      <div style="width:85%;height:750px;"  class="absolute  right-0 bottom-20 bg-red-700">
        <div class="absolute" style="top:-100px;left:-100px;width:46%;"><img src="../assets/home/Group 64.png" alt=""></div>
        <div  class="absolute text-left text-white" style="right:18%;top:80px;width:600px;">
          <h6 style="font-size: 24px;height: 30px;">{{ $t('message.home.features.product3.name') }}</h6>
          <ul style="list-style: disc;font-size: 18px;line-height: 28px;">
            <li>{{ $t('message.home.features.product3.content1') }}</li>
            <li>{{ $t('message.home.features.product3.content2') }}</li>
            <li>{{ $t('message.home.features.product3.content3') }}</li>
            <li>{{ $t('message.home.features.product3.content4') }}</li>
          </ul>
        </div>
         <div class="absolute" style="bottom:-100px;right:100px;width:46%;"><img src="../assets/home/p4.png" alt=""></div>
        <div  class="absolute text-left text-white" style="left:80px;bottom:80px;width:600px;">
          <h6 style="font-size: 24px;height: 30px;">{{ $t('message.home.features.product4.name') }}</h6>
          <ul style="list-style: disc;font-size: 18px;line-height: 28px;">
            <li>{{ $t('message.home.features.product4.content1') }}</li>
            <li>{{ $t('message.home.features.product4.content2') }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="divide" style="background: rgba(0,0,0,0.8);margin-top:150px;">
      <div class="flex items-center">
        <span class="arrow-lef mr-16"><img src="../assets/home/arr-l.png" alt="" srcset=""></span>
        <span class="text-white" style="font-size: 48px;z-index: 1;">{{ $t('message.home.main.title') }}</span>
        <span class="arrow-right ml-16"><img src="../assets/home/arr-r.png" alt="" srcset=""></span>
      </div>
      <div class=" tracking-wider" style="color: rgba(241,241,241,0.3);font-size: 48px;margin-top:-45px;">MAIN PRODUCT</div>
    </div>
    <div style="background: #F8F8F8;padding: 1px;">
      <div class="part5 flex flex-wrap" style="width:1340px; margin:130px auto;">
        <div class="img"><img src="../assets/home/mp1.png" alt="" srcset=""></div>
        <div class="text"> <i class="markleft"></i> {{ $t('message.home.main.product1') }}</div>
        <div class="img"><img src="../assets/home/mp2.png" alt="" srcset=""></div>
        <div class="text"><i class="markleft"></i>{{ $t('message.home.main.product2')  }}</div>
  
        <div class="text"><i class="markright"></i>{{ $t('message.home.main.product3') }}</div>
        <div class="img"><img src="../assets/home/mp3.png" alt="" srcset=""></div>
        <div class="text"><i class="markright"></i>{{ $t('message.home.main.product4') }}</div>
        <div class="img"><img src="../assets/home/mp4.png" alt="" srcset=""></div>
  
        <div class="img"><img src="../assets/home/mp5.png" alt="" srcset=""></div>
        <div class="text"><i class="markleft"></i>{{ $t('message.home.main.product5')  }}</div>
        <div class="img"><img src="../assets/home/mp6.png" alt="" srcset=""></div>
        <div class="text"><i class="markleft"></i>{{ $t('message.home.main.product6') }}</div>
      </div>
    </div>
    <!--  -->
    <div class="divide" style="background: rgba(0,0,0,0.8);margin-bottom:150px;">
      <div class="flex items-center">
        <span class="arrow-lef mr-16"><img src="../assets/home/arr-l.png" alt="" srcset=""></span>
        <span class="text-white" style="font-size: 48px;z-index: 1;">{{ $t('message.home.ezt.title') }}</span>
        <span class="arrow-right ml-16"><img src="../assets/home/arr-r.png" alt="" srcset=""></span>
      </div>
      <div class=" tracking-wider" style="color: rgba(241,241,241,0.3);font-size: 48px;margin-top:-45px;">EZtCloud platform</div>
    </div>
    <div class="part4 relative" style="height:720px;">

      <div style="width:85%;height:500px;" class="absolute right-0 top-20 bg-red-700">
        <div class="absolute" style="top:-140px;left:-180px;width:46%;"><img src="../assets/home/Group 60.png" alt=""></div>

        <div class="absolute  text-left text-white" style="left:658px;top:75px;width:447px;font-size: 18px;">
          {{ $t('message.home.ezt.content') }}
          <a href="https://www.eztcloud.com" target="_blank">
            <div class="absolute  text-center" style="left:0px;bottom: -60px;width: auto;  background-color: #fff;color: #B02922; line-height: 24px;border-radius: 2px;font-weight: 600;font-size: 16px; padding: 6px">
              {{ $t('message.home.ezt.btn') }} <img src="../assets/home/Group 42@2x.png" alt="" class="" style=" display: inline-block; width: 14px;height: 14px;">
            </div>
          </a>
        </div>
      </div>

    </div>
    <!--  -->
    <div class="part6" style="">
      <div class="divide" style="">
        <div class="flex items-center">
          <span class="arrow-lef mr-16"><img src="../assets/home/arr-l.png" alt="" srcset=""></span>
          <span class="text-white" style="font-size: 48px;z-index: 1;">{{ $t('message.home.us.title') }}</span>
          <span class="arrow-right ml-16"><img src="../assets/home/arr-r.png" alt="" srcset=""></span>
        </div>
        <div class=" tracking-wider" style="color: #CA6E7499;font-size: 48px;margin-top:-45px;">ABOUT US</div>
      </div>
      <div class="flex justify-center" style="padding-bottom:140px;">
        <div><img src="../assets/home/ab1.png" alt=""></div>
        <div style="width:600px;" class="text-left text-white mx-10">
          {{ $t('message.home.us.content') }}
          <div class="text-right">
            <!-- <span class="text-red-700 bg-gray-200 p-2 rounded-sm">了解更多</span> -->
          </div>
        </div>
        <div><img src="../assets/home/ab2.png" alt=""></div>
      </div>
    </div>
    <div class="divide" style="">
      <div class="flex items-center">
        <span class="arrow-lef mr-16"><img src="../assets/home/arr-l-r.png" alt="" srcset=""></span>
        <div class=""  style="font-size: 48px;z-index: 1;color:#000000FF;">{{ $t('message.home.serve.title') }}</div>
        <span class="arrow-right ml-16"><img src="../assets/home/arr-r-r.png" alt="" srcset=""></span>
      </div>
      <div class=" tracking-wider" style="color: #CA6E7499;font-size: 48px;margin-top:-45px;">SERVICE SYSTEM</div>
    </div>
    <div class="part7 text-left relative" style="padding-bottom:100px; margin-left:10%;margin-right:10%;">
      <div style="font-size: 16px;">
        <p>{{ $t('message.home.serve.content1') }}</p>
        <p>{{ $t('message.home.serve.content2') }}</p>
        <p>{{ $t('message.home.serve.content3') }}</p>
        <p>{{ $t('message.home.serve.content4') }}</p>
        <p>{{ $t('message.home.serve.content5') }}</p>
      </div>
      <div style="margin-left:50%;margin-top:80px;width:350px;min-height:120px;">
        <!-- <h6 style="font-size: 20px;margin-bottom:20px;">联系方式</h6>
        <div class="flex">
          <div>电话：</div>
          <div class="flex-1">0371-63611002</div>
        </div>
        <div class="flex">
          <div>地址：</div>
          <div class="flex-1">中国(上海)自由贸易试验区临港新片区 沧海路2828号10-1号厂房5楼南侧</div>
        </div>
        <div class="flex">
          <div>邮箱：</div>
          <div class="flex-1">market@leadot.com.cn</div>
        </div> -->
      </div>
      <div class="absolute" style="top:-150px;right:3%;z-index: -1; width:40%;"><img src="../assets/home/map.png" alt="" srcset=""></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
<style lang="less" scoped>
::marker{
  font-size:30px;
}
::v-deep .el-carousel .el-carousel__container{
  min-height: 500px;
}
.divide{
  height: 270px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.part1{
  .item{
    // margin: 0px 100px;
    .icbox{
      width: 256px;
      height: 252px;
      background: #EEEEEE;
      box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
      opacity: 1;
      border: 1px solid #D8D8D8;
      border-radius: 50%;
      img{
        width: 116px;
      }
    }
  }
}
.part2{
  .part{
    flex:1;
    text-align: center;
    .imgbox{
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .namebox{
      min-height: 3.5em;
    }
  }
}
.cpzx{
  background-image: url("../assets/home/cpzx.png");
}
.part5{
  .img{
    background: #EFEFEFFF;
    img{
      max-height:90%;
    }
  }
  .text{
    background: #fff;
    position: relative;
    z-index: 1;
    padding: 5px;
    .markleft{
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: transparent white transparent transparent;
      top:110px;
      left:-20px;
    }
    .markright{
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: transparent  transparent transparent white;
      top:110px;
      right:-20px;
    }
  }
  .text,.img{
    width: 25%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.part6{
  background-image: url('../assets/home/ab-bg.png');
  background-size: 100% 100%;
}
.part7{
  p{
    max-width: 50%;
  }
}
</style>
